import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./pages/Hero";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import About from "./pages/About";
 
const App = () => {

   // Deshabilitar clic derecho
   const handleContextMenu = (event) => {
    event.preventDefault();
  };
  document.addEventListener("contextmenu", handleContextMenu);

  
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <Hero />
      <Services />
      <About />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
