import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSent, setIsSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSent(true); // Indica que el mensaje fue enviado
  };

  return (
    <section
      id="contact"
      className="py-20 bg-gradient-to-br from-pastelLavender to-pastelCream px-4"
    >
      <div className="container mx-auto text-center">
        <h2 className="text-4xl sm:text-5xl font-bold text-primary mb-8">
          Contáctanos
        </h2>

        {isSent ? (
          <div className="bg-white shadow-md p-6 rounded-lg text-center">
            <h3 className="text-2xl font-semibold text-primary mb-4">
              ¡Gracias por tu mensaje!
            </h3>
            <p className="text-gray-700">
              Nos pondremos en contacto contigo a la brevedad.
            </p>
          </div>
        ) : (
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            className="max-w-md mx-auto p-6 rounded-lg shadow-lg"
            action="/thank-you-page.html"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                No llenar este campo: <input name="bot-field" />
              </label>
            </p>
            <input
              type="text"
              name="name"
              placeholder="Tu nombre"
              className="w-full mb-4 p-3 rounded-lg shadow-md"
              required
              onChange={handleChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Tu correo"
              className="w-full mb-4 p-3 rounded-lg shadow-md"
              required
              onChange={handleChange}
            />
            <textarea
              name="message"
              placeholder="Tu mensaje"
              className="w-full mb-4 p-3 rounded-lg shadow-md"
              required
              onChange={handleChange}
            ></textarea>
            <button
              type="submit"
              className="bg-primary text-white py-3 px-6 rounded-lg hover:bg-darkGreen transition"
            >
              Enviar
            </button>
          </form>
        )}
      </div>
    </section>
  );
};

export default Contact;
