import React from "react";
import { motion } from "framer-motion";
import {
  SparklesIcon,
  GlobeAltIcon,
  CogIcon,
  ChartBarIcon,
  ChatBubbleBottomCenterTextIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";

const Services = () => {
  const services = [
    {
      title: "Diseño de Logos",
      description: "Logos únicos que reflejan la identidad de tu negocio.",
      icon: <SparklesIcon className="w-14 h-14 text-primary" />,
    },
    {
      title: "Diseño de Contenido",
      description:
        "Creamos tus flyers e ilustraciones para acompañar las necesidades visuales de tu marca.",
      icon: <PencilIcon className="w-14 h-14 text-primary" />,
    },
    {
      title: "Páginas Web",
      description: "Desarrollo de sitios modernos y responsivos.",
      icon: <GlobeAltIcon className="w-14 h-14 text-primary" />,
    },
    {
      title: "Software a Medida",
      description: "Soluciones personalizadas para optimizar procesos.",
      icon: <CogIcon className="w-14 h-14 text-primary" />,
    },
    {
      title: "Gestión de Redes Sociales",
      description: "Estrategias efectivas para conectar con tu audiencia.",
      icon: <ChatBubbleBottomCenterTextIcon className="w-14 h-14 text-primary" />,
    },
    {
      title: "Publicidad Digital",
      description: "Campañas que impactan y generan resultados.",
      icon: <ChartBarIcon className="w-14 h-14 text-primary" />,
    },
  ];

  return (
    <motion.section
      id="services"
      className="py-20 bg-gradient-to-br from-pastelLavender to-pastelCream"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4">
        <h2 className="text-4xl sm:text-5xl font-bold text-center text-primary mb-12">
          Nuestros Servicios
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="relative bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition duration-300"
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="flex justify-center mb-4">{service.icon}</div>
              <h3 className="text-xl font-bold text-center text-primary mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600 text-center">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default Services;
