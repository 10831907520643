import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

const Hero = () => {
  return (
    <motion.section
      id="hero"
      className="relative bg-gradient-to-br from-pastelLavender to-pastelCream h-screen flex flex-col justify-center items-center text-center px-4 sm:px-8 overflow-hidden"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      {/* Contenido de Texto */}
      <div className="z-10">
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-primary leading-tight">
          Bienvenidos a
        </h1>
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-primary leading-tight">
          KAIOBA
        </h1>
        <p className="text-base sm:text-lg md:text-xl text-darkGreen mt-4 max-w-md mx-auto">
          Conectamos creatividad y tecnología para crear experiencias únicas.
        </p>
        <Link
          to="services"
          smooth={true}
          duration={500}
          className="inline-block mt-8 bg-primary text-white py-2 sm:py-3 px-4 sm:px-6 rounded-lg hover:bg-darkGreen transition"
        >
          Conócenos
        </Link>
      </div>

      {/* Michi Espacial Flotante */}
      <motion.img
        src="/assets/MICHI_ESPACIAL_A_COLOR.png"
        alt="Michi Espacial"
        className="absolute bottom-5 sm:bottom-10 left-5 sm:left-10 w-20 sm:w-28 md:w-36 lg:w-48"
        animate={{ y: [0, -10, 0] }}
        transition={{ repeat: Infinity, duration: 2 }}
      />
    </motion.section>
  );
};

export default Hero;
