import React from "react";
import { motion } from "framer-motion";

const projects = [
  {
    title: "Alpha Courier",
    category: "Diseños y gestión de Redes",
    image: "/portfolio/alpha_courier.webp",
  },
  {
    title: "Pangolin",
    category: "Logo, Ilustraciones y Diseños personalizados",
    image: "/portfolio/pangolin.webp",
  },
  {
    title: "Escribanía Magali Benítez",
    category: "Diseños de cartelería, merch y Página web",
    image: "/portfolio/escribania_benitez.webp",
  },
  {
    title: "Diori",
    category: "Logo, Diseños y Videos personalizados",
    image: "/portfolio/diori.webp",
  },
  {
    title: "Crearte",
    category: "Logo y gestión de Redes",
    image: "/portfolio/crearte.webp",
  },
  {
    title: "Gamepoint",
    category: "Logo, Diseños y gestión de Redes",
    image: "/portfolio/gamepoint.webp",
  },
];

const Portfolio = () => {
  return (
    <motion.section
      id="portfolio"
      className="py-20 bg-gradient-to-br from-pastelLavender to-pastelCream"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4">
        <h2 className="text-4xl sm:text-5xl font-bold text-center text-primary mb-12">
          Portafolio
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              className="relative group overflow-hidden rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition duration-300"
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              {/* Imagen del Proyecto */}
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-56 md:h-64 object-cover group-hover:scale-110 transition-transform duration-300"
              />
              {/* Contenido del Overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                <div className="text-center text-white px-4">
                  <h3 className="text-2xl font-bold">{project.title}</h3>
                  <p className="text-sm mt-2">{project.category}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default Portfolio;
