import React from "react";

const Footer = () => {
  return (
    <footer className="relative bg-darkGreen text-pastelCream py-12 overflow-hidden">
      {/* Fondo con marca de agua */}
      <img
        src="/assets/MARCA_DE_AGUA.png"
        alt="Marca de Agua"
        className="absolute inset-0 w-full h-full opacity-10 object-cover pointer-events-none"
      />

      {/* Contenido */}
      <div className="container mx-auto text-center relative z-10 px-4">
        {/* Logo */}
        <div className="mb-6">
          <img
            src="/assets/LOGO_CREMA_FONDOS_CLAROS.png"
            alt="Kaioba Logo"
            className="mx-auto w-28 md:w-36"
          />
        </div>

        {/* Texto */}
        <p className="text-sm md:text-base text-pastelCream">
          © {new Date().getFullYear()} Kaioba. Todos los derechos reservados.
        </p>

        {/* Redes Sociales */}
        <div className="flex justify-center gap-6 mt-6">
          <a
            href="https://www.instagram.com/somoskaioba"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:scale-125 transition-transform duration-300"
            aria-label="Instagram"
          >
            <img
              src="/assets/instagram-icon.svg"
              alt="Instagram"
              className="w-6 md:w-8 h-6 md:h-8"
            />
          </a>
          <a
            href="https://www.facebook.com/somoskaioba"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:scale-125 transition-transform duration-300"
            aria-label="Facebook"
          >
            <img
              src="/assets/facebook-icon.svg"
              alt="Facebook"
              className="w-6 md:w-8 h-6 md:h-8"
            />
          </a>
      
        </div>
      </div>

      {/* Elementos Decorativos */}
      <div className="absolute top-0 left-0 w-16 h-16 bg-pastelYellow rounded-full blur-3xl opacity-20"></div>
      <div className="absolute bottom-0 right-0 w-20 h-20 bg-pastelLavender rounded-full blur-3xl opacity-20"></div>
    </footer>
  );
};
 /* <a
            href="mailto:descubre@kaioba.com"
            className="hover:scale-125 transition-transform duration-300"
            aria-label="Email"
          >
            <img
              src="/assets/email-icon.svg"
              alt="Email"
              className="w-6 md:w-8 h-6 md:h-8"
            />
          </a> */
export default Footer;

