import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <motion.section
      id="about"
      className="py-20 bg-gradient-to-br from-pastelCream to-pastelLavender text-darkGreen"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
        {/* Columna Izquierda: Imagen */}
        <motion.div
          className="relative"
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7 }}
        >
          <img
            src="/assets/COHETE_A_COLOR.png"
            alt="Cohete Kaioba"
            className="w-full max-w-sm mx-auto"
          />
          <motion.img
            src="/assets/ESTRELLA_KAIOBA.png"
            alt="Estrella Kaioba"
            className="absolute top-0 left-10 w-16 md:w-20 opacity-80"
            animate={{ rotate: [0, 360] }}
            transition={{ repeat: Infinity, duration: 10 }}
          />
        </motion.div>

        {/* Columna Derecha: Texto */}
        <motion.div
          initial={{ x: 50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7 }}
        >
          <h2 className="text-4xl sm:text-5xl font-bold text-primary mb-6">
            Sobre Nosotros
          </h2>
          <p className="text-lg leading-relaxed mb-6">
            En <span className="font-semibold text-darkGreen">Kaioba</span>, somos un equipo apasionado que convierte ideas en realidades. Nuestro propósito es empoderar a marcas y emprendedores mediante soluciones creativas y estratégicas que destacan en un mundo competitivo.
          </p>
          <p className="text-lg leading-relaxed">
            Con creatividad, tecnología y un enfoque estratégico, ayudamos a que tu marca conecte con su audiencia y alcance nuevos horizontes.
          </p>
        </motion.div>
      </div>

      {/* Elementos Decorativos */}
      <div className="absolute top-0 right-0 w-16 h-16 bg-pastelYellow rounded-full blur-3xl opacity-20"></div>
      <div className="absolute bottom-0 left-0 w-20 h-20 bg-pastelLavender rounded-full blur-3xl opacity-20"></div>
    </motion.section>
  );
};

export default About;
